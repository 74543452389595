import React, { useMemo } from "react";
import { css } from "goober";
import { mobile, mobileMedium, tabletMedium } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import definition from "@/game-marvel-rivals/definition.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Maps() {
  const {
    contentful: { related },
  } = useSnapshot(readState);
  const maps = useMemo(
    () =>
      related.filter((i) =>
        ["marvelRivals", "map"].every((t) => i.tags.find((j) => j === t)),
      ),
    [related],
  );
  if (!Array.isArray(maps) || !maps.length) return null;
  return (
    <Container className={classNames(cssMaps())?.className}>
      {maps.map((map) => {
        return (
          <a
            href={`/${definition.shortName}/article/${map.id}`}
            key={map.id}
            className="flex column gap-2"
          >
            <img src={map.imageUrl} alt={map.title} className="br w-full" />
            <p className="type-subtitle1">{map.title}</p>
          </a>
        );
      })}
    </Container>
  );
}

const cssMaps = () =>
  css({
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    gap: "var(--sp-4)",
    [tabletMedium]: {
      gridTemplateColumns: "repeat(3,1fr)",
    },
    [mobile]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [mobileMedium]: {
      gridTemplateColumns: "1fr",
    },
  });

export function meta(): Meta {
  return {
    title: [
      "marvelrivals:meta.maps.title",
      "Marvel Rivals Maps Guide: Best Tips, Tricks & Strategies - Blitz",
    ],
    description: [
      "marvelrivals:meta.maps.description",
      "Master every map in Marvel Rivals with our in-depth guides, strategies, and pro tips. Learn the best hero placements, objectives, hidden routes, and winning tactics to dominate the battlefield. Stay ahead with the latest map insights and expert tricks for every location in the game.",
    ],
  };
}
